<template>
  <div class="terms">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <h1 style="visibility:hidden; height: 0px; margin: 0">Terms of Use</h1>
        <div class="row">
          <div class="col half">
            <h3>Introduction</h3>
            <p>
              Welcome to INCREDEVENT (the "Service")! We are a merchant and
              trust account platform connecting event planners and ticket buyers
              around the world. Through our platform users can create, find, and
              purchase event tickets from event planners ("planner",
              "coordinator", "buyer").
            </p>
            <p>
              These Terms and Conditions ("Terms", "Terms and Conditions",
              "Terms of Use") govern your relationship with
              https://incredevent.com website operated by INCREDEVENT ("us",
              "we", or "our"), and your purchasing agreement with the ticket
              planner. This includes all websites, domains, webpages,
              subdomains, and country level domain variants.
            </p>
            <p>
              Please read these Terms and Conditions carefully before using the
              Service.
            </p>
            <p>
              Your access to and use of the Service is based on your acceptance
              of and compliance with these Terms. These Terms apply to all
              visitors, users and others who access or use the Service.
            </p>
            <p>
              By accessing or using the Service you agree to be bound by these
              Terms and accept all legal consequences. If you do not agree to
              these terms and conditions, in whole or in part, please do not use
              the Service.
            </p>
            <h3>Purchases</h3>
            <p>
              If you wish to purchase any product or service made available
              through the Service ("Purchase"), you may be asked to supply
              certain information relevant to your Purchase including, without
              limitation, your credit card number, secret code, the expiration
              date of your credit card, your billing address, and your shipping
              information.
            </p>
            <p>
              You represent and warrant that: (i) you have the legal right to
              use any credit card(s) or other payment method(s) in connection
              with any Purchase; and that (ii) the information you supply to us
              is true, correct and complete.
            </p>
            <p>
              By submitting such information, you grant us the right to provide
              the information to third parties for purposes of facilitating the
              completion of Purchases.
            </p>
            <p>
              We reserve the right to refuse or cancel your order at any time
              for certain reasons including but not limited to: product or
              service availability, errors in the description or price of the
              product or service, error in your order or other reasons.
            </p>
            <p>
              We reserve the right to refuse or cancel your order if fraud or an
              unauthorised or illegal transaction is suspected. We will not be
              held responsible or liable for any failure for the Purchase to
              complete, or any resulting loss or damages to you.
            </p>
            <h3>Chargebacks</h3>
            <p>
              INCREDEVENT purchases are final unless the event planner accepts
              refunds, which is outlined on the event page. Chargebacks are only
              accepted if fraud can be proven. Fraud would require proof that
              you did not purchase the tickets and did not attend the event. In
              the event of a chargeback request, the event must have concluded
              before our platform will respond.
            </p>
            <p>
              Chargebacks are not issued for any of the following reasons:
              personal experience and taste, your child using your method of
              payment, in the event of a multi-talent experience; select acts
              not showing, weather, inability to find transportation, late
              attendance, illness, or any other event outside of the event
              planner's control.
            </p>
            <h3>Events</h3>
            <p>
              All event payouts to those whom host events (the Planner) on
              INCREDEVENT are to be paid out within 8-business days of the event
              end date, unless signed up for Express Pay (which pays out after
              fraud protection is run and scanned). For events that are ongoing
              from known Planners (5 events or more hosted on INCREDEVENT)
              payments will be made at the end of the month for moneys owing and
              currently available to INCREDEVENT.
            </p>
            <p>
              INCREDEVENT reserves the right to run all purchases through fraud
              protection and certify purchases as authentic. This process can
              take 10-business days, and while ticket purchases are a part of
              this process, those funds are not available to the Planner.
            </p>
            <h3>Technology</h3>
            <p>
              INCREDEVENT is an infrastructure that uses third party
              applications and services (ie: PayPal, QR Code Providers). In the
              event of a technological issue on the site INCREDEVENT is not held
              liable for any down time. Bugs are to be reported to
              411@incredevent.com. If the bug cannot be fixed within 5-business
              days, INCREDEVENT will inform the Planner or Buyer of a next
              course of action. At that time, the Planner can choose to remove
              the INCREDEVENT service, and the Buyer can ask for a refund.
            </p>
            <h3>Availability, Errors and Inaccuracies</h3>
            <p>
              In order to provide exceptional service, and accuracy, we
              regularly update the products and services on the Service.
            </p>
            <p>
              We cannot and do not guarantee the accuracy or completeness of any
              information, including prices, product images, specifications,
              availability, and services. We reserve the right to change or
              update information and to correct errors, inaccuracies, or
              omissions at any time without prior notice.
            </p>
            <p>
              Despite our best efforts, the products or services available on
              our Service may have an error regarding the price, be inaccurately
              described, or be unavailable.
            </p>
            <p>
              We may experience delays in updating information on the Service
              and in our advertising on other websites.
            </p>
            <h3>Contests, Sweepstakes and Promotions</h3>
            <p>
              Any contests, sweepstakes or other promotions (collectively,
              "Promotions") made available through the Service may be governed
              by rules that are separate from these Terms. If you participate in
              any Promotions, please review the applicable rules as well as our
              Privacy Policy. If the rules for a Promotion conflict with these
              Terms, the Promotion rules will apply.
            </p>
            <p>Promotions may be available for a limited time.</p>
            <h3>Content</h3>
            <p>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material ("Content"). You are responsible for the Content
              that you post to the Service, including its legality, reliability,
              and appropriateness.
            </p>
            <p>
              By posting Content to the Service, you grant us the right and
              license to use, modify, publicly perform, publicly display,
              reproduce, and distribute such Content on and through the Service.
              You retain any and all of your rights to any Content you submit,
              post or display on or through the Service and you are responsible
              for protecting those rights. You agree that this license includes
              the right for us to make your Content available to other users of
              the Service, who may also use your Content subject to these Terms.
            </p>
            <p>
              You represent and warrant that: (i) the Content is yours (you own
              it) or you have the right to use it and grant us the rights and
              license as provided in these Terms, and (ii) the posting of your
              Content on or through the Service does not violate the privacy
              rights, publicity rights, copyrights, contract rights or any other
              rights of any person.
            </p>
            <p>
              We reserve all rights to block or remove communications or
              materials that we determine to be: (a) abusive, defamatory, or
              obscene; (b) fraudulent, deceptive, or misleading; (c) in
              violation of a copyright, trademark or, other intellectual
              property right of another or; (d) offensive or otherwise
              unacceptable to us in our sole discretion.
            </p>
            <p>
              You acknowledge that, by providing you with the ability to view
              and distribute user-generated content on the Service, we are
              merely acting as a passive conduit for such distribution and is
              not undertaking any obligation or liability relating to any
              contents or activities on the Service.
            </p>
            <h3>Accounts</h3>
            <p>
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service.
            </p>
            <p>
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>
            <p>
              You may not use as a username the name of another person or entity
              or that is not lawfully available for use, a name or trademark
              that is subject to any rights of another person or entity other
              than you without appropriate authorization, or a name that is
              otherwise offensive, vulgar or obscene.
            </p>
            <h3>Copyright Policy</h3>
            <p>
              We respect the intellectual property rights of others. It is our
              policy to respond to any claim that Content posted on the Service
              infringes the copyright or other intellectual property
              infringement ("Infringement") of any person.
            </p>
            <p>
              If you are a copyright owner, or authorized on behalf of one, and
              you believe that the copyrighted work has been copied in a way
              that constitutes copyright infringement that is taking place
              through the Service, you must submit your notice in writing to the
              attention of "Copyright Infringement" to us and include in your
              notice a detailed description of the alleged Infringement.
            </p>
            <p>
              You may be held accountable for damages (including costs and
              attorneys' fees) for misrepresenting that any Content is
              infringing your copyright.
            </p>
          </div>
          <div class="col half">
            <h3>Intellectual Property</h3>
            <p>
              The Service and all contents, including but not limited to text,
              images, graphics or code are the property of INCREDEVENT and are
              protected by copyright, trademarks, database and other
              intellectual property rights. You may display and copy, download
              or print portions of the material from the different areas of the
              Service only for your own non-commercial use, or to place an order
              with INCREDEVENT. Any other use is strictly prohibited and may
              violate copyright, trademark and other laws. These Terms do not
              grant you a license to use any trademark of INCREDEVENT or its
              affiliates. You further agree not to use, change or delete any
              proprietary notices from materials downloaded from the Service.
            </p>
            <h3>Links To Other Web Sites</h3>
            <p>
              The Service may contain links to third-party web sites or services
              that are not owned or controlled by INCREDEVENT.
            </p>
            <p>
              INCREDEVENT has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services. You further acknowledge and agree
              that INCREDEVENT shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with use of or reliance on any such content,
              goods or services available on or through any such websites or
              services.
            </p>
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </p>
            <h3>Termination</h3>
            <p>
              We may terminate or suspend access to our Service immediately,
              without prior notice or liability, for any reason whatsoever,
              including, without limitation, if you breach the Terms.
            </p>
            <p>
              All provisions of the Terms shall survive termination, including,
              without limitation, ownership provisions, warranty disclaimers,
              indemnity and limitations of liability.
            </p>
            <p>
              Upon termination, your right to use the Service will immediately
              cease. If you wish to terminate your account, you may simply
              discontinue using the Service.
            </p>
            <h3>Indemnification</h3>
            <p>
              You agree to indemnify, defend and hold harmless INCREDEVENT, its
              principals, officers, directors, representatives, employees,
              contractors, licensors, licensees, suppliers and agents, from and
              against any claims, losses, damages, losses, obligations, costs,
              actions or demands.
            </p>
            <p>
              These include but are not limited to: (a) legal and accounting
              fees resulting from your use of the Service; (b) your breach of
              any of these Terms; (c) anything you post on or upload to the
              Service; and (d) any activity related to your account. This
              includes any negligent or illegal conduct by you, any person or
              entity accessing the Service using your account whether such
              access is obtained via fraudulent or illegal means.
            </p>
            <p>
              You also agree that the Service acts as a merchant and trust
              account between ticket buyers and event planners. In the event
              that there is a dispute over refunds, all legal liabilities are
              held by the event planner. Further, you agree that there may be a
              wait period for refunds as event planners may withdraw ticket
              funds from their INCREDEVENT trust and merchant account at any
              time. For more information regarding wait periods for the event
              that you are buying tickets, please contact the event planner.
            </p>
            <p>INCREDEVENT is indemnified, and held harmless.</p>
            <h3>Limitation Of Liability</h3>
            <p>
              INCREDEVENT, its directors, employees, partners, agents,
              suppliers, or affiliates, shall not be liable for any loss or
              damage, direct or indirect, incidental, special, consequential or
              punitive damages, including without limitation, economic loss,
              loss or damage to electronic media or data, goodwill, or other
              intangible losses, resulting from (i) your access to or use of the
              Service; (ii) your inability to access or use the Service; (iii)
              any conduct or content of any third-party on or related to the
              Service; (iiv) any content obtained from or through the Service;
              and (v) the unauthorized access to, use of or alteration of your
              transmissions or content, whether based on warranty, contract,
              tort (including negligence) or any other claim in law, whether or
              not we have been informed of the possibility of such damage, and
              even if a remedy set forth herein is found to have failed of its
              essential purpose.
            </p>
            <p>
              In the event that fraud is perpetrated on an event, the planner is
              responsible for the fraud. This means the refund will come out of
              moneys collected for said event. In a situation where the moneys
              are paid out to the event planner and fraud is identified at a
              later date, the event planner must return those moneys to
              INCREDEVENT within five (5) business days. In the event that the
              event planner does not return the funds, they will be held legally
              responsible for the funds and all charges in accordance with legal
              action.
            </p>
            <h3>Disclaimer And Non-Waiver of Rights</h3>
            <p>
              INCREDEVENT makes no guarantees, representations or warranties of
              any kind as regards the website and associated technology. Any
              purportedly applicable warranties, terms and conditions are
              excluded, to the fullest extent permitted by law. Your use of the
              Service is at your sole risk. The Service is provided on an "AS
              IS" and "AS AVAILABLE" basis. The Service is provided without
              warranties of any kind, whether express or implied, including, but
              not limited to, implied warranties of merchantability, fitness for
              a particular purpose, non-infringement or course of performance,
              except as provided for under the laws of any province in Canada.
              In such cases, the provincial law shall apply to the extent
              necessary.
            </p>
            <p>
              INCREDEVENT its subsidiaries, affiliates, and its licensors do not
              warrant that a) the Service will function uninterrupted, secure or
              available at any particular time or location; b) any errors or
              defects will be corrected; c) the Service is free of viruses or
              other harmful components; or d) the results of using the Service
              will meet your requirements.
            </p>
            <p>
              If you breach any of these Terms and INCREDEVENT chooses not to
              immediately act, or chooses not to act at all, INCREDEVENT will
              still be entitled to all rights and remedies at any later date, or
              in any other situation, where you breach these Terms. INCREDEVENT
              does not waive any of its rights. INCREDEVENT shall not be
              responsible for any purported breach of these Terms caused by
              circumstances beyond its control. A person who is not a party to
              these Terms shall have no rights of enforcement.
            </p>
            <p>
              You may not assign, sub-license or otherwise transfer any of your
              rights under these Terms.
            </p>
            <h3>Exclusions</h3>
            <p>
              As set out, above, some jurisdictions do not allow the exclusion
              of certain warranties or the exclusion or limitation of liability
              for consequential or incidental damages, so the limitations above
              may not apply to you. Provincial laws of Canada may apply to
              certain products and service provided.
            </p>
            <h3>Governing Law</h3>
            <p>
              These Terms shall be governed by, and interpreted and enforced in
              accordance with, the laws in the Province of Manitoba and the laws
              of Canada, as applicable.
            </p>
            <p>
              If any provision of these Terms is held to be invalid or
              unenforceable by a court of competent jurisdiction, then any
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements, oral or
              otherwise, regarding the Service.
            </p>
            <h3>Changes</h3>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will make
              reasonable efforts to provide at least 30 days' notice prior to
              any new terms taking effect. What constitutes a material change
              will be determined at our sole discretion.
            </p>
            <p>
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, in whole or in part, please
              stop using the website and the Service.
            </p>
            <h3>Currency & Conversion</h3>
            <p>
              Our cart handles all currency and purchase information that is
              available to us internally. If your bank or purchase method
              provider charges additional rates, these would be above our quoted
              price.
            </p>
            <p>
              In regards to currency conversion, our cart updates every 2 hours.
              It is possible for currency to fluctuate in that time period and
              for you to be charged a different rate than is stated in our
              purchase flow.
            </p>
            <h3>Contact Us</h3>
            <p>
              If you have any questions about these Terms, please
              <router-link to="/contact-us">contact us</router-link>
              .
            </p>
          </div>
        </div>
        <div class="divider"></div>
        <p>Updated January 15, 2023.</p>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
//

.row {
  margin: 0;
  width: initial !important;

  a {
    display: contents;
  }
}

h3 {
  margin: 0;
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  // #Content .content-inner {
  //   width: 88%;
  // }
}
</style>

<script>
export default {
  name: "terms",
  head() {
    return {
      title: { inner: "Terms of Use" },
      meta: [
        {
          name: "description",
          content:
            "Event Management. Ticket Sales. Event Directory. Industry-leading software for creating & discovering experiences. Read INCREDEVENT's terms here.",
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion",
        },
        {
          name: "author",
          content: "INCREDEVENT",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },
      ],
    };
  },
  data() {
    return {
      linkColor: "var(--primary-green)",
    };
  },
  created() {
    this.$store.commit("setTitle", "Terms of Use");
  },
  mounted() {
    if (this.$store.state.site === "buyers") {
    }
  },
};
</script>
